import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {DateService} from '../date/date.service';
import {catchError, lastValueFrom, Observable} from 'rxjs';
import {HttpErrorService} from '../http-error/http-error.service';
import {SessionService} from '../session/session.service';
import {Product} from '../../models/register/product/product';
import {ComboBoxOption} from '../../models/combo-box-option';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private readonly baseUrl: string;
  private static CATEGORIES: any[] = [
    {label: 'Campanha', value: 'C'},
    {label: 'Permanente', value: 'P'}
  ];
  private static TARGETS: any[] = [
    {
      label: 'Pessoa física',
      value: 1,
      items: [
        {
          label: 'Estilo/Estilo digital',
          value: 1
        },
        {
          label: 'Private',
          value: 2
        },
        {
          label: 'Personalizado/Exclusivo',
          value: 3
        },
        {
          label: 'Universitário',
          value: 4
        },
        // {
        //   label: 'Setor público',
        //   value: 5
        // },
        {
          label: 'Mercado emergente',
          value: 7
        },
        {
          label: 'Varejo',
          value: 27
        }
      ]
    },
    {
      label: 'Pessoa jurídica',
      value: 2,
      items: [
        // {
        //   label: 'Varejo',
        //   value: 6,
        //   items: [
        //     {
        //       label: 'Microempresa',
        //       value: 13
        //     },
        //     {
        //       label: 'MEI',
        //       value: 25
        //     },
        //     {
        //       label: 'Cooperativa',
        //       value: 26
        //     }
        //   ]
        // },
        {
          label: 'Large corporate',
          value: 8
        },
        {
          label: 'Corporate',
          value: 9
        },
        {
          label: 'Empresarial',
          value: 23,
          items: [
            {
              label: 'Upper midle',
              value: 10
            },
            {
              label: 'Midle',
              value: 14
            }
          ]
        },
        {
          label: 'MPE',
          value: 24,
          items: [
            {
              label: 'Empresa',
              value: 11
            },
            {
              label: 'Microempresa',
              value: 13
            },
            {
              label: 'MEI - Micro Empreendedor Individual',
              value: 25
            },
            {
              label: 'Pequena empresa',
              value: 12
            }
          ]
        }
      ]
    },
    {
      label: 'Setor público',
      value: 3,
      items: [
        {
          label: 'Judiciário',
          value: 15
        },
        {
          label: 'Federal',
          value: 16
        },
        {
          label: 'Estadual',
          value: 17
        },
        {
          label: 'Municipal',
          value: 18,
          items: [
            {
              label: 'Capitais e metrópoles',
              value: 19
            },
            {
              label: 'Grandes municípios',
              value: 20
            },
            {
              label: 'Médios municípios',
              value: 21
            },
            {
              label: 'Pequenos municípios',
              value: 22
            }
          ]
        }
      ]
    },
    {
      label: 'Institucional',
      value: 4,
      items: [
        {
          label: 'Institucional',
          value: 28
        }
      ]
    }
  ];
  private static MEANS: any[] = [
    {label: 'Canais próprios do BB', value: 9},
    {label: 'Cinema', value: 13},
    {label: 'Eventos e feiras', value: 6},
    {label: 'Internet', value: 4},
    {label: 'Intranet', value: 5},
    {label: 'Jornal', value: 2},
    {label: 'Mídia Exterior', value: 3},
    {label: 'Mídias alternativas', value: 7},
    // {label: 'Para Baixar', value: 15},
    {label: 'Pontos de Venda (PDV)', value: 8},
    {label: 'Redes Sociais', value: 14},
    {label: 'Revista', value: 1},
    {label: 'TV Aberta', value: 11},
    {label: 'TV Fechada', value: 12}
  ];
  // private static PRODUCTION_STAGE: any[] = [
  //   {label: 'Produzir', value: 'P'}, //dimac'},
  //   {label: 'Arte final', value: 'AF'}, //outras-dependencias'}
  // ];
  private static PRODUCTION_STAGE: any[] = [
    {label: 'Para produzir', value: 'P'}, //dimac'},
    {label: 'Para download', value: 'AF'}, //outras-dependencias'}
  ];
  private static TARGET_AREAS: ComboBoxOption[] = [
    {label: 'Território Nacional', value: 'N'},
    {label: 'Estados do Brasil', value: 'E'},
    {label: 'Municípios do Brasil', value: 'M'},
    {label: 'Território Internacional', value: 'I'},
    {label: 'Países', value: 'P'}
  ];
  private static YES_NO: ComboBoxOption[] = [
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
  ];
  private static SUBJECTS: ComboBoxOption[] = [
    {label: 'Mercadológico', value: 1},
    {label: 'Institucional', value: 2}
  ];
  // private static DELIVERY_WAYS: ComboBoxOption[] = [
  //   {label: 'Para todos', value: 'C'},
  //   {label: 'Restrito', value: 'P'}
  // ];
  private static DELIVERY_WAYS: ComboBoxOption[] = [
    {
      label: '1. Estoque para grupos',
      value: 'C',
      data: 'As peças ficam estocadas para que cada dependência faça o pedido pelo sistema. É preciso indicar a quantidade de peças que cada grupo de dependências pode pedir. Os grupos já estão pré-definidos no sistema, bastando apenas indicar a quantidade máxima de cada um.'
    },
    {
      label: '2. Distribuição automática',
      value: 'A',
      data: 'Nesse tipo de distribuição determina-se o destino final das peças, indicando o quantitativo de cada prefixo por meio de planilha específica. O sistema gera pedidos e envios automáticos ao destino, eliminando estocagem de peças.'
    },
    {
      label: '3. Distribuição direta',
      value: 'D',
      data: 'Nesse tipo de distribuição as peças não passam pela estocagem, indo direto do fornecedor contratado para a dependência de destino. Exemplo, instalação de painel em agência ou envio de folder para uma Superintendência.'
    },
    {
      label: '4. Estoque restrito',
      value: 'P',
      data: 'As peças ficam estocadas para que cada dependência faça o pedido pelo sistema, de maneira restrita aos prefixos autorizados. É preciso indicar quais os prefixos estarão autorizados e a quantidade.'
    }
  ];

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiUrl + '/register/product';
  }

  public combobox(kind: string, term: string | null = null): Promise<any> {
    const token: string = SessionService.getToken();

    return lastValueFrom(this.http.get<any>(this.baseUrl + '/combobox/' + kind + '?term=' + term + '&token=' + token))
      .then(data => data);
  }

  public all(start: Date, end: Date, filters?: string, first: number = 0, rows: number = 5, sorts?: string): Promise<any> {
    const ss: string = DateService.date(start);
    const se: string = DateService.date(end);

    const token: string = SessionService.getToken();
    let url: string = this.baseUrl + '/?start=' + ss + '&end=' + se + '&token=' + token;

    if ((filters !== undefined) && (filters !== null) && (filters.length > 0)) //
      url += '&filters=' + filters;

    if ((first !== undefined) && (first !== null) && (first > 0)) //
      url += '&first=' + first;

    if ((rows !== undefined) && (rows !== null) && (rows > 0)) //
      url += '&rows=' + rows;

    if ((sorts !== undefined) && (sorts !== null) && (sorts.length > 0)) //
      url += '&sorts=' + sorts;

    return lastValueFrom(this.http.get<any>(url))
      .then(data => data);
  }

  public generateCustomCardPreview(custom: any): Observable<HttpEvent<any>> {
    const token: string = SessionService.getToken();

    return this.http.post<any>(this.baseUrl + '/custom-card/generate-preview?token=' + token, custom, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json'
    });
  }

  public add(item: Product, draft: boolean = false): Observable<any> {
    const token: string = SessionService.getToken();

    console.log(item);

    return this.http.post<any>(this.baseUrl + '/?draft=' + draft + '&token=' + token, item, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public get(id: number): Observable<any> {
    const token: string = SessionService.getToken();

    return this.http.get<any>(this.baseUrl + '/' + id + '?&token=' + token, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public update(id: number, item: Product): Observable<any> {
    const token: string = SessionService.getToken();

    return this.http.put<any>(this.baseUrl + '/' + id + '?&token=' + token, item, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public delete(id: string): Observable<any> {
    const token: string = SessionService.getToken();
    let url: string = this.baseUrl + '/' + id + '?token=' + token;

    return this.http.delete(url, {
      // headers: {
      //   'Authorization': 'Bearer ' + UserService.getToken()
      // }
    }).pipe(catchError(HttpErrorService.management));
  }

  public static getCategories(): ComboBoxOption[] {
    return ProductService.CATEGORIES;
  }

  public static getTargets(): ComboBoxOption[] {
    return ProductService.TARGETS;
  }

  public static getMeans(): ComboBoxOption[] {
    // SELECT nome AS label, id AS `value` FROM meio_divulgacao WHERE removida = 0 ORDER BY nome ASC
    return ProductService.MEANS;
  }

  public static getProductionStage(): ComboBoxOption[] {
    return ProductService.PRODUCTION_STAGE;
  }

  public static getTargetAreas(): ComboBoxOption[] {
    return ProductService.TARGET_AREAS;
  }

  public static getYesNo(): ComboBoxOption[] {
    return ProductService.YES_NO;
  }

  public static getSubjects(): ComboBoxOption[] {
    return ProductService.SUBJECTS;
  }

  public static getDeliveryWays(): ComboBoxOption[] {
    return ProductService.DELIVERY_WAYS;
  }

}
